// noinspection AllyPlainJsInspection

export const LOCAL_STORAGE_KEYS = {
  LANG: 'luba.uilang',
  CURRENT_CHECKPOINT_USER: 'luba.currentCheckpointUser',
  BGCHECK(applicationId) {
    return `luba.backgroundCheck.${applicationId}`;
  },
  BGCHECK_UPLOADED_FILE(applicationId) {
    return `${this.BGCHECK(applicationId)}.uploadedFile`;
  },
  BGCHECK_WIZARD_STEP(applicationId) {
    return `${this.BGCHECK(applicationId)}.wizardStep`;
  },
};

export const LANG = {
  EN: 'en',
  ET: 'et',
};

export const VALIDATION = {
  email: 'common.error.invalid_email_address',
  required: 'common.error.value_required',
};

export const SecondaryApplicationReasons = {
  PeriodExtension: 'periodExtension',
  CardholderDataChange: 'cardholderDataChange',
  PermissionChange: 'permissionChange',
  NewAirport: 'newAirport',
  CardLost: 'cardLost'
};

export const ApplicationTypes = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  All: 'All'
};

export const ApplicationSearchSortBy = {
  Nr: 0,
  Firstname: 1,
  Lastname: 2,
  Company: 3,
  Code: 4,
}

export const PermitSearchSortBy = {
  Nr: 1,
  ApplicationNr: 2,
  CardHolder: 3,
  ValidUntil: 4,
  Company: 5,
}

export const DashboardActionStyle = { padding: '4px 8px', borderRadius: 0 };

export const TallinnAirportCompanyNames = [
  'AS Tallinna Lennujaam',
  'Tallinna Lennujaam AS',
  'Airport City AS',
  'AS Airport City',
  'Tallinn Airport GH AS',
  'Tallinn Airport GH',
  'AS Tallinn Airport GH'
];
export const HRDepartments = ['Inimeste ja organisatsioonikultuuri osakond'];

export const SignTypes = {
  IdCard: 'IdCard',
  SmartId: 'SmartId',
  MobileId: 'MobileId',
};