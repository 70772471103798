import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'app/config';

export const publicLubaApi = createApi({
  reducerPath: 'publicLubaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
  }),
  endpoints: (builder) => ({
    getApplicationByIdAndControlCode: builder.query({
      query({ applicationId, controlCode }) {
        return `/cardholder/${applicationId}/${controlCode}`;
      },
    }),
    getClassifiers: builder.query({
      query: () => `/classificator/list`,
      keepUnusedDataFor: 60 * 60 * 24 * 7, // keep for a week
    }),
    getClassifierValues: builder.query({
      query: (classifierId) => `/classificator/values/${classifierId}`,
      keepUnusedDataFor: 60 * 60 * 24, // keep for a day
    }),

    uploadPhotoByHolder: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/photo`,
          method: 'POST',
          body,
        };
      },
    }),
    updateApplication: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareApplicationIdCard: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/signprepare`,
          method: 'POST',
          body,
        };
      },
    }),
    signApplicationWithIdCard: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/sign`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareApplicationSmartId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/smartid_prepare`,
          method: 'POST',
          body,
        };
      },
    }),
    signApplicationWithSmartId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/smartid_sign`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareApplicationMobileId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/mobileid_prepare`,
          method: 'POST',
          body,
        };
      },
    }),
    signApplicationWithMobileId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/mobileid_sign`,
          method: 'POST',
          body,
        };
      },
    }),
    validateApplication: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/validate`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareBackgroundCheckIdCard: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/signprepare`,
          method: 'POST',
          body,
        };
      },
    }),
    signBackgroundCheckWithIdCard: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/sign`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareBackgroundCheckSmartId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/smartid_prepare`,
          method: 'POST',
          body,
        };
      },
    }),
    signBackgroundCheckWithSmartId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/smartid_sign`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareBackgroundCheckMobileId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/mobileid_prepare`,
          method: 'POST',
          body,
        };
      },
    }),
    signBackgroundCheckWithMobileId: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/mobileid_sign`,
          method: 'POST',
          body,
        };
      },
    }),
    validateBackgroundCheck: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/validate`,
          method: 'POST',
          body,
        };
      },
    }),
    uploadBackgroundCheck: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/upload`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetApplicationByIdAndControlCodeQuery,
  useUploadPhotoByHolderMutation,
  useUpdateApplicationMutation,
  useUploadBackgroundCheckMutation,
  useValidateApplicationMutation,
  useValidateBackgroundCheckMutation,

  // application signing
  useSignPrepareApplicationIdCardMutation,
  useSignApplicationWithIdCardMutation,
  useSignPrepareApplicationSmartIdMutation,
  useSignApplicationWithSmartIdMutation,
  useSignPrepareApplicationMobileIdMutation,
  useSignApplicationWithMobileIdMutation,

// bgcheck signing
  useSignPrepareBackgroundCheckIdCardMutation,
  useSignBackgroundCheckWithIdCardMutation,
  useSignPrepareBackgroundCheckSmartIdMutation,
  useSignBackgroundCheckWithSmartIdMutation,
  useSignPrepareBackgroundCheckMobileIdMutation,
  useSignBackgroundCheckWithMobileIdMutation,

  useGetClassifierValuesQuery,
  useGetClassifiersQuery,
} = publicLubaApi;
