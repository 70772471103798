import { split, merge, set, reduce, includes } from 'lodash';

const defaultConfig = {
  API_BASE_URL: "https://back-app.blackground-ee26dc68.northeurope.azurecontainerapps.io",
  AUTH: {
    msal: {
      auth: {
        clientId: "a583ad86-31d9-471f-b766-5f92da04d7b0",
        authority: "https://flora.tll.aero/adfs",
        redirectUri: "https://front-app.blackground-ee26dc68.northeurope.azurecontainerapps.io",
        postLogoutRedirectUri: "https://front-app.blackground-ee26dc68.northeurope.azurecontainerapps.io",
        knownAuthorities: split("flora.tll.aero", ','),
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    loginRequest: {
      scopes: split("openid", ','),
    },
  },
};

// environment variable names to config value paths
const CfgPathMapping = {
  API_BASE_URL: 'API_BASE_URL',
  MSAL_AUTH_CLIENTID: 'AUTH.msal.auth.clientId',
  MSAL_AUTH_REDIRECTURI: 'AUTH.msal.auth.redirectUri',
  MSAL_AUTH_POSTLOGOUTREDIRECTURI: 'AUTH.msal.auth.postLogoutRedirectUri',
  MSAL_AUTH_AUTHORITY: 'AUTH.msal.auth.authority',
  MSAL_AUTH_KNOWNAUTHORITIES: 'AUTH.msal.auth.knownAuthorities',
  MSAL_AUTH_LOGINSCOPES: 'AUTH.loginRequest.scopes',
};

const runtimeConfig = reduce(window?.LubaUiAppConfig || {}, (acc, value, key) => {
  if (includes([CfgPathMapping.MSAL_AUTH_LOGINSCOPES, CfgPathMapping.MSAL_AUTH_KNOWNAUTHORITIES], key)) {
    set(acc, CfgPathMapping[key], split(value, ','));
  } else {
    set(acc, CfgPathMapping[key], value);
  }
  return acc;
}, {});

const config = merge({}, defaultConfig, runtimeConfig);

console.log('CONFIG', config);
console.log('ENV', process.env);
console.log('DEFAULT_CONFIG', defaultConfig);
console.log('LubaUiAppConfig', window.LubaUiAppConfig);
console.log('runtimeConfig', runtimeConfig);

export default config;
